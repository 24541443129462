<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import divisionsTable from './components/divisionsTable';
export default {
  page: {
    title: "Divisions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, divisionsTable },
  data() {
    return {
      tableData: [],
      title: "Divisions",
      divisions: [],
      items: [
        {
          text: "Hiérarchie",
          href: "javascript:void(0)"
        },
        {
          text: "Divisions",
          active: true
        }
      ],
      
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },
  
  
  methods: {
    /**
     * Search the table data with search input
     */
    

    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <divisions-table/>
  </Layout>
</template>